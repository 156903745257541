import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Anchor from "@components/Anchor"
import HomeHero from "@components/narcolepsy/HomeHero"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2 } from "@styles/ui"
import Callout from "@components/narcolepsy/CallOut"
import MyWavVideoSection from "@components/MyWavVideoSection"
import BgImageSection from "@components/narcolepsy/BgImageSection/"

// Assets
import risk from "@images/narc-home/risk-infographic.svg"
import coupon from "@images/narc-home/coupon.svg"
import standupChampionsLogo from "@images/logos/logo-sucs.svg"

const NarcolepsyHomepage = ({ location, data }) => {
  const supImage = withArtDirection(getImage(data.collageDesktop), [
    {
      media: "(max-width: 768px)",
      image: getImage(data.collageMobile),
    },
  ])

  return (
    <Layout location={location}>
      <Seo
        title="XYWAV® | Treatment for 2 Symptoms of Narcolepsy"
        description="Learn about XYWAV® (calcium, magnesium, potassium, and sodium oxybates), an oral solution for patients with cataplexy and/or excessive daytime sleepiness in narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <HomeHero
        imageData={data}
        altText="Patient speaking to a doctor with an elephant (made of salt) in the room"
      />
      <section tw="py-4 bg-lilac text-center">
        <Container>
          <p tw="text-sm lg:text-xl">
            XYWAV is for people 7 years of age or older with cataplexy and/or
            excessive daytime sleepiness (EDS) in narcolepsy
          </p>
        </Container>
      </section>

      {/* Cardiovascular risk */}
      <section tw="py-[30px] md:py-[50px]">
        <Container grid>
          <div tw="mb-[30px] md:(col-span-3 mb-0 self-center) lg:col-span-4">
            <img src={risk} alt="" tw="w-full max-w-[270px] mx-auto md:mx-0" />
          </div>
          <div tw="md:(col-start-4 col-span-8) lg:(col-start-5 col-span-8)">
            <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6)"></div>
            <h2 css={[Heading2, tw`mb-[15px] xl:mb-[25px]`]}>
              Did you know that people with narcolepsy are at an increased risk
              of certain cardiovascular conditions?{" "}
            </h2>
            <div tw="md:(grid grid-cols-2 gap-x-6)">
              <p tw="mb-4 md:(max-w-[370px] mb-0)">
                A study has shown that people with narcolepsy were at a 2.5x
                higher risk<sup>&dagger;</sup> to have a stroke and 1.6x higher
                risk
                <sup>&dagger;</sup> to have a heart attack than people without
                narcolepsy.<sup>&Dagger;</sup>
              </p>
              <p>
                Some risk factors for cardiovascular disease are modifiable,
                such as excessive sodium intake. Fortunately, for most adults,
                reducing sodium intake by 1000 mg per day can help reduce the
                risk for high blood pressure, heart disease, and stroke.
              </p>
            </div>
            <div tw="mt-4 text-[12px] leading-4 md:(text-xs leading-[19px])">
              <ul tw="list-none pl-2">
                <li tw="mb-0.5 -indent-2">
                  <sup>&dagger;</sup>Odds ratio.
                </li>
                <li tw="-indent-2">
                  <sup>&Dagger;</sup>A retrospective analysis of US medical
                  claims data for 9312 people with narcolepsy and a control
                  group of 46,559 without narcolepsy.
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section tw="py-[15px] text-center bg-lilac md:(py-[25px])">
        <Container>
          <p>
            XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
            oxybates) oral solution, 0.5 g/mL total salts (equivalent to 0.413
            g/mL of oxybate) is a prescription medicine used to treat the
            following symptoms in people 7 years of age or older with
            narcolepsy: sudden onset of weak or paralyzed muscles (cataplexy)
            and excessive daytime sleepiness (EDS)
          </p>
        </Container>
      </section>

      <BgImageSection
        imageData={data}
        isWhiteBg
        widerImage
        smallImageMargin
        mobileAspectRatio={"71%"}
        altText="Patient speaking to a doctor with an elephant (made of salt) in the room"
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-6`]}>
            Discover the only oxybate
            <br />
            with low&nbsp;sodium
          </h2>
          <p tw="mb-6">
            High&#8209;sodium oxybates contain ~1640&nbsp;mg of sodium at the
            maximum recommended nightly dose, meaning{" "}
            <strong>
              you would consume more sodium than American Heart
              Association&apos;s 1500&nbsp;mg ideal sodium limit for most
              adults&mdash;all before eating or drinking anything
            </strong>
            .
          </p>
          <p tw="mb-6">
            XYWAV, with 131 mg of sodium at the maximum recommended nightly
            dose, is the first and only low-sodium oxybate treatment approved by
            the FDA for treating cataplexy and/or excessive daytime sleepiness
            (EDS) in people 7 years of age or older with narcolepsy.
          </p>
          <p tw="mb-6">
            Learn more about why choosing XYWAV over high-sodium oxybates can
            help reduce your sodium intake, a modifiable risk factor for
            cardiovascular disease.
          </p>
          <PrimaryCta
            url={"/narcolepsy/lower-sodium-oxybate-treatment-option/"}
          >
            <>XYWAV AND SODIUM</>
          </PrimaryCta>
        </>
      </BgImageSection>

      <Callout
        heading={
          <>
            XYWAV is thought to work during sleep to help improve symptoms of
            cataplexy and EDS during the day<sup>&sect;</sup>
          </>
        }
      >
        <>
          <p tw="text-[12px] leading-4 font-extrabold md:(text-xs leading-[19px])">
            <sup>§</sup>The exact way XYWAV works for the treatment of cataplexy
            and EDS in patients with narcolepsy is unknown.
          </p>
        </>
      </Callout>

      {/* Financial assistance */}
      <section tw="py-[30px] md:py-[50px]">
        <Container>
          <div tw="md:(grid gap-4 grid-cols-2 place-items-start items-center)">
            <div tw="mb-[30px] mx-auto max-w-[340px] md:(mx-0 max-w-[470px] mb-0 )">
              <img src={coupon} alt="" />
            </div>
            <div>
              <div tw="before:(gradientRed block relative w-[124px] mb-5 h-1.5 rounded-md) md:before:(mb-6)"></div>
              <h2 css={[Heading2, tw`mb-5 md:mb-7`]}>
                The XYWAV Coupon Program
              </h2>
              <p>
                Pay as little as $5 for XYWAV through the Coupon Program. Want
                to find out if you are eligible?<sup>&#8214;&para;</sup>{" "}
                JazzCares<sup>&reg;</sup> is here to help.
              </p>

              <div tw="text-[12px] leading-4 mt-5 md:(text-xs leading-[19px] mt-[25px])">
                <ul tw="list-none sm:pl-2.5">
                  <li tw="-indent-2">
                    <sup>&#8214;</sup>Applies only to commercially insured
                    patients enrolled in the XYWAV and XYREM REMS. Subject to
                    maximum annual benefit.
                  </li>
                  <li tw="-indent-2">
                    <sup>&para;</sup>You must be a resident of the US, Puerto
                    Rico, or other US&nbsp;territory.
                  </li>
                </ul>
              </div>

              <p tw="text-[12px] leading-4 mt-5 md:(text-xs leading-[19px] mt-[25px])">
                Jazz Pharmaceuticals reserves the right to terminate or modify
                this program at any time with or without notice. Other{" "}
                <Anchor
                  link="https://jazzcares.com/patients/xywav/#termsandconditions"
                  isExternal
                  newTab
                >
                  <nobr>Terms and Conditions</nobr>
                </Anchor>{" "}
                apply.
              </p>
            </div>
          </div>
        </Container>
        <Container>
          <div tw="text-center mt-[60px] md:mt-[75px]">
            <h2 css={[Heading2, tw`mb-5 md:text-[32px]`]}>
              Financial and insurance support, based on your personal needs
            </h2>
            <p tw="mb-5 text-sm md:text-xl">
              Jazz Pharmaceuticals is here to help you find programs that may
              help you reduce the cost of XYWAV. Plus, JazzCares can help
              support your doctor&apos;s office in navigating your specific
              insurance requirements to help get you the treatment you need.
            </p>
            <PrimaryCta url="/narcolepsy/jazzcares-for-xywav/">
              Show me how to save
            </PrimaryCta>
          </div>
        </Container>
      </section>

      {/* Video */}
      <MyWavVideoSection thumbnail={data.thumbImage} theme="narcolepsy" />

      {/* Standup champions */}
      <section tw="md:(flex items-center h-[410px]) lg:(relative h-[455px] max-w-[1400px] mx-auto)">
        <div tw="relative md:(absolute right-0 grid grid-cols-12 h-[327px]) lg:(grid-cols-3 h-[405px])">
          <GatsbyImage
            image={supImage}
            alt=""
            tw="relative z-10 md:(col-span-8 col-start-5) lg:(col-start-2)"
          />
          <div tw="narc-suc-gradient-no-buffer absolute left-0 bottom-0 w-screen h-[30px] block z-20 md:z-10"></div>
          <div tw="hidden narc-suc-gradient-no-buffer-y absolute left-0 bottom-0 h-full w-[50px] z-10 md:(block col-span-8 col-start-5) lg:(col-start-2)"></div>
        </div>
        <Container grid>
          <div tw="order-2 md:(max-w-[280px] col-span-5 order-1 h-[327px]) lg:(max-w-[unset] col-span-5 h-[405px])">
            <img
              src={standupChampionsLogo}
              alt=""
              tw="w-full max-w-[250px] xl:max-w-[268px]"
            />
            <h2 css={[Heading2, tw`my-[15px] lg:(my-[25px])`]}>
              Hear from real patients taking XYWAV
            </h2>
            <p tw="mb-[15px] lg:(mb-[25px] max-w-[372px])">
              Standup Champions are patients who take XYWAV to treat their
              cataplexy and/or EDS in narcolepsy, and want to share their
              experiences to help and inspire others. Watch videos from people
              living with narcolepsy and learn how they fit XYWAV treatment into
              their lives.
            </p>
            <div tw="text-center md:text-left">
              <PrimaryCta url="/patient-stories/">
                Watch patient stories
              </PrimaryCta>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default NarcolepsyHomepage

export const query = graphql`
  query {
    homeHeroDesktop: file(
      relativePath: { eq: "narc-home/elephant_standing_desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 950, placeholder: NONE)
      }
    }
    homeHeroMobile: file(
      relativePath: { eq: "narc-home/elephant_standing_mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    collageDesktop: file(relativePath: { eq: "narc-home/suc-collage.png" }) {
      childImageSharp {
        gatsbyImageData(width: 905, placeholder: NONE)
      }
    }
    collageMobile: file(
      relativePath: { eq: "narc-home/suc-collage-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    thumbImage: file(
      relativePath: { eq: "video-thumbnails/mywav-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    bgImageSection: file(
      relativePath: { eq: "narc-home/woman-elephant-standing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "narc-home/woman-elephant-standing-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    bottleImage: file(relativePath: { eq: "what-is-xywav/bottle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
  }
`
