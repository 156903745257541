import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import "twin.macro"

// Components
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"

const HomeHero = ({ imageData, altText }) => {
  const images = withArtDirection(getImage(imageData.homeHeroDesktop), [
    {
      media: "(max-width: 768px)",
      image: getImage(imageData.homeHeroMobile),
    },
  ])

  return (
    <section tw="relative overflow-hidden md:(grid min-h-[520px])">
      <div tw="relative md:(absolute top-0 right-0 grid grid-cols-12 justify-items-end overflow-hidden)">
        <div tw="relative overflow-hidden md:(col-span-7 col-start-7) md-lg:col-start-6">
          <GatsbyImage
            tw="md:(h-[520px]) md-lg:(w-[130%]) "
            image={images}
            alt={altText}
            imgStyle={{
              width: "100%",
              height: "100%",
            }}
          />
          <div tw="mobile-narc-home-hero-gradient absolute left-0 bottom-0 w-screen h-[110px] block md:(narc-home-hero-gradient top-0 h-[520px] w-[208px])"></div>
        </div>
      </div>
      <Container>
        <div tw="relative pt-5 py-10 md:(py-0 grid h-full grid-cols-12 overflow-hidden)">
          <div tw="col-span-6 md:(flex items-center justify-start) md-lg:col-span-5">
            <div tw="md:(max-w-[570px] pt-[70px])">
              <h1 tw="font-cocon narc-text-gradient leading-[0] lg:(leading-[0])">
                <span tw="font-avenir font-normal text-5xl leading-[35px] lg:(text-[40px] leading-[45px])">
                  It&lsquo;s time to address
                  <br />
                  the high-sodium
                </span>
                <span tw="block text-6xl leading-[48px] pt-2 lg:(text-[65px] leading-[65px])">
                  elephant in
                  <br />
                  the room
                </span>
              </h1>
              <p tw="mt-4 mb-2 md:font-normal">
                Talk to your doctor about low-sodium XYWAV.<sup>*</sup>
              </p>
              <p tw="text-[12px] leading-4 mb-6 md:(text-xs leading-[19px] )">
                <sup>*</sup>XYWAV contains 131 mg of sodium at the maximum
                recommended nightly dose.
              </p>
              <PrimaryCta url={"/narcolepsy/what-is-xywav/"} mdSmall>
                What is XYWAV
              </PrimaryCta>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeHero

HomeHero.prototypes = {
  imageData: PropTypes.object.isRequired,
  altText: PropTypes.string,
}

HomeHero.defaultProps = {
  altText: "",
}
